<template>
    <div>
        <topmenu :comname="'floorplan'"></topmenu>
        <div class="widht1240">
            <div class="t-map">
                您当前的位置：
                <span>展位图</span>
            </div>
            <div class="widht200 fl">
                <contact></contact>
            </div>
            <div class="widht1010 bg-white fr padding10">
                <!-- <a :href="curUrl"><img :src="curUrl" alt="" width="100%" height="100%"></a> -->
                <el-image 
                    style="width: 100%; height: 100%"
                    :src="curUrl" 
                    :preview-src-list="listImg">
                </el-image>
            </div>
        </div>
        <bottominfo></bottominfo>
    </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default{
    name:"floorplan",
    data(){
        return{
            curUrl:"",
            listImg:['','']
        }
    },
    components:{
        topmenu,
        bottominfo,
        contact,
        nocont
    },
    created(){
        this.getBooth();
    },
    methods:{
        getBooth(){
            this.$axios.post("/api/rally/getExhibitionImgCollection").then(res => {
            if (res[0].length > 0) {               
                let demo = res[0];
                this.curUrl = demo[0].imgUrl;
                this.listImg[0]= demo[0].imgUrl;
                // console.log(this.curUrl);
            }
            });
        }
    }
}
</script>
<style scoped>

</style>
